import {List, ListItem} from '@common/ui/list/list';
import {PersonIcon} from '@common/icons/material/Person';
import {Trans} from '@common/i18n/trans';

import {LockIcon} from '@common/icons/material/Lock';

import {DangerousIcon} from '@common/icons/material/Dangerous';
import {ReactNode} from 'react';

import {useAuth} from '@common/auth/use-auth';
import {useSettings} from '@common/core/settings/use-settings';

export enum AccountSettingsId {
  AccountDetails = 'account-details',
  SocialLogin = 'social-login',
  Password = 'password',
  TwoFactor = 'two-factor',
  LocationAndLanguage = 'location-and-language',
  Developers = 'developers',
  DeleteAccount = 'delete-account',
  Sessions = 'sessions',
}

export function AccountSettingsSidenav() {
  const p = AccountSettingsId;

  const {hasPermission} = useAuth();
  const {api, social} = useSettings();

  const socialEnabled =
    social?.envato || social?.google || social?.facebook || social?.twitter;

  return (
    <aside className="flex-shrink-0 sticky top-10 hidden lg:block">
      <List padding="p-0">
        <Item icon={<PersonIcon />} panel={p.AccountDetails}>
          <Trans message="Account details" />
        </Item>
     
        <Item icon={<LockIcon />} panel={p.Password}>
          <Trans message="Password" />
        </Item>
       
        <Item icon={<DangerousIcon />} panel={p.DeleteAccount}>
          <Trans message="Delete account" />
        </Item>
      </List>
    </aside>
  );
}

interface ItemProps {
  children: ReactNode;
  icon: ReactNode;
  isLast?: boolean;
  panel: AccountSettingsId;
}
function Item({children, icon, isLast, panel}: ItemProps) {
  return (
    <ListItem
      startIcon={icon}
      className={isLast ? undefined : 'mb-10'}
      onSelected={() => {
        const panelEl = document.querySelector(`#${panel}`);
        if (panelEl) {
          panelEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }}
    >
      {children}
    </ListItem>
  );
}
