import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {useUser} from '../use-user';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
 
import {BasicInfoPanel} from './basic-info-panel/basic-info-panel';
import {ChangePasswordPanel} from './change-password-panel/change-password-panel';

import {DangerZonePanel} from './danger-zone-panel/danger-zone-panel';
import {Trans} from '@common/i18n/trans';
import {StaticPageTitle} from '@common/seo/static-page-title';

import {
  AccountSettingsId,
  AccountSettingsSidenav,
} from '@common/auth/ui/account-settings/account-settings-sidenav';


export function AccountSettingsPage() {
  const {data, isLoading} = useUser('me', {
    with: ['roles', 'social_profiles', 'tokens'],
  });
  return (
    <div className="bg-alt min-h-screen">
      <StaticPageTitle>
        <Trans message="Account Settings" />
      </StaticPageTitle>
      <Navbar menuPosition="account-settings-page" />
      <div>
        <div className="container mx-auto my-24 px-24">
          <h1 className="text-3xl">
            <Trans message="Account settings" />
          </h1>
          <div className="mb-40 text-muted text-base">
            <Trans message="View and update your account details, profile and more." />
          </div>
          {isLoading || !data ? (
            <ProgressCircle
              className="my-80"
              aria-label="Loading user.."
              isIndeterminate
            />
          ) : (
            <div className="flex items-start gap-24">
              <AccountSettingsSidenav />
              <main className="flex-auto">
                <BasicInfoPanel user={data.user} />
                
                <ChangePasswordPanel />

                


                
                <DangerZonePanel />
              </main>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
